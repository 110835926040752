import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { API } from '../../../api';
import { UploadData } from '../../../models/video';
import VideoPlayer from '../../../components/UX/player/Player';
import authStore from '../../../stores/authStore';
import { RejectionDialog } from './RejectionDialog';
import { RejectWithPaymentDialog } from './RejectWithPaymentDialog';
import { ApproveDialog } from './ApproveDialog';

const { adminRole } = authStore;

interface ProjectMapItemDialogProps {
	isOpen: boolean;
	onClose: () => void;
	sessionUploadId: number;
}

export const VideoItemDialog = (props: ProjectMapItemDialogProps) => {
	const { isOpen, onClose, sessionUploadId } = props;
	const [item, setItem] = useState<UploadData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false);
	const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
	const [isRejectWithPaymentDialogOpen, setIsRejectWithPaymentDialogOpen] = useState<boolean>(false);

	const uploadId = item?.id;

	const VideoDialogContent = useMemo(() => {
		return (
			<Box display={'flex'} style={{ backgroundColor: 'black', maxHeight: '562px', height: '100%' }}>
				<VideoPlayer videoSrc={item?.url as string} hideVideoOverlay />
			</Box>
		);
	}, [sessionUploadId, isLoading]);

	useEffect(() => {
		setIsLoading(true);
		API.getUpload(sessionUploadId)
			.then(res => {
				setItem(res.data.upload);
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [sessionUploadId]);

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="xl"
			PaperProps={{
				style: {
					height: 'auto',
					overflowY: 'auto'
				}
			}}>
			<DialogContent>
				<div style={{ maxHeight: '762px' }}>{VideoDialogContent}</div>
			</DialogContent>
			{adminRole === 'superadmin' || adminRole === 'admin' ? (
				<DialogActions>
					<Button onClick={() => setIsApproveDialogOpen(true)}>Accept</Button>
					<Button onClick={() => setIsRejectWithPaymentDialogOpen(true)}>Reject with payment</Button>
					<Button onClick={() => setIsRejectDialogOpen(true)}>Reject</Button>
					<Button onClick={onClose}>Close</Button>
				</DialogActions>
			) : (
				<></>
			)}
			<RejectionDialog isOpen={isRejectDialogOpen} onClose={() => setIsRejectDialogOpen(false)} uploadId={uploadId} onItemDialogClose={() => {}} />
			<RejectWithPaymentDialog
				isOpen={isRejectWithPaymentDialogOpen}
				onClose={() => setIsRejectWithPaymentDialogOpen(false)}
				uploadId={uploadId}
				onItemDialogClose={() => {}}
			/>
			<ApproveDialog isOpen={isApproveDialogOpen} onClose={() => setIsApproveDialogOpen(false)} uploadId={uploadId} onItemDialogClose={() => {}} />
		</Dialog>
	);
};
